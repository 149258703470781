/*
www.hiof.no/fss x1 local-fss.less

Activate here:
https://www.hiof.no/vrtx/set-design.html
*/

:root {
  
  --color-light: #fff;
  --color-primary: #013384; //mørk blå (fra logoen)
  --color-secondary: #00aeef; //klarblå
  --color-neutral:  #C8C2BE; //varmgrå
  --color-neutral-light: #ECEAE5; //lys varmgrå

  --color-neutral-45: #e6e4e2; 
  --color-neutral-30: #f7f6f5;
  --color-neutral-15: #efedeb;
  --color-dypbla: #005c8e;
  --color-dypbla-45: #86b6cc;
  --color-dypbla-30: #b2cedd;
  --color-dypbla-15: #d9e7ee;
  --color-secondary-45: #8cdbf8;
  --color-secondary-30: #b2e7fa;
  --color-secondary-15: #d9f3fd;
  --color-secondary-10: #eff7fd;

  --color-link: #013384; 
  --color-breadcrumb: #013384;
  --color-nav-links: #d9f3fd; // 15% klarblå #D9e7ee;
  --color-date-hex: #8cb6cc;
  --color-button: #013384;
  --color-button-hover: #8cb6cc;
  
  --logo-eng: url("../images/fss/logo-eng.svg");
  --logo: url("../images/fss/logo.svg");
  --img-footer: url(../images/fss/footer-fss.svg);
  --img-button-large-arrow: url("../images/arrow-white.svg");
  --img-button-arrow: url("../images/fss/arrow.svg");
  --img-right-arrow: url(../images/fss/arrow-blue.svg);
  --img-avd-search: url(../images/fss/search-blue.svg);
  --img-avd-menu: url(../images/fss/menu-blue.svg);
  --img-chevron: url(../images/fss/chevron.svg);
//  --img-cubes-decor: url("../images/fss/cubes-decor.svg");
  --img-location-pin: url("../images/fss/location-pin.svg");
  --img-caret-down: url("../images/fss/caret-down.svg");
  --img-quote: url("../images/fss/quote.svg");
}
  

//All fss-colors from old design
  // Primary, secondary etc...
// @color-primary: #013384;
// @color-primary--dark: #002a6e;
// @color-primary--darker: #001e4d;
// @color-primary--light: #d3e2f0;
// @color-primary--lighter: #009fea;
// @color-primary--air: #eef8fd;
// @color-secondary: #d3ece8;

body.fss {
  #head-wrapper {
    background-color: var(--color-secondary-10);
    padding-bottom: 67px;
    color: var(--color-link);

    #head {
      #header .logo {
        background-image: url(../images/fss/logo.svg);
        background-repeat: no-repeat;
        margin: 0;
        height: 58px;
        row-gap: 25px;
        a.subtitle {
          color: var(--color-link);
          font-size: 1.8rem;
          padding: var(--space-xs) 0 var(--space-s) 0;
        }
      }

      #header-tools {
        background-color: transparent;
        .toggle-search::before {
          background-image: var(--img-avd-search);
        }
        .search-form {
          bottom: -20px;
        }    
        .sidebar-menu-toggle::before {
          background-image: var(--img-avd-menu);
        }
      }
    }
  }
  
  #main-menu * {
    background-color: var(--color-secondary-10);
    color: var(--color-link);
  }

  #breadcrumbs span::after {
    background-image: var(--img-chevron);
  }

  #main {
    margin-top: 0;
  }

  .the-frontpage #main::before {
    background: none;
    height: auto;
  }

  .read-more::before,
  .vrtx-more a::before,
  .vrtx-read-more::before,
  .all-messages::before {
    background-image: var(--img-right-arrow);
    background-repeat: no-repeat;
  }

  #main button, #vrtx-searchview .vrtx-big-search button {
    &:hover, &:focus {
      color: black;
      background-color: var(--color-button-hover);
    }
  }
  .button::after {
    background-image: var(--img-button-arrow);
    height: 1.5em;
    top: 15px;
  }
  
  .vrtx-frontpage-box {
    &.img-special-left,
    &.img-special-right,
    &.box-special {
      background: var(--color-neutral-30);
      &.blue {
        background: var(--color-dypbla-15);
      }
    }
  }

  #main {
    .vrtx-article-body .vrtx-facts-container, .vrtx-facts-container {
      &.blue {
        background: var(--color-dypbla-15);
      }
    }
  }

  .vrtx-contact-component {
    background-color: var(--color-neutral-15);
  }
  
  #footer-wrapper {
    background-color: var(--color-light);

    &::after {
      content: "";
      display: block;
      background: var(--img-footer);
      background-size: cover;
      aspect-ratio: 776/269;
      margin-top: 50px;
    }
  }
}
html[lang=en] body.fss {
  #head-wrapper #head #header .logo {
    background-image: var(--logo-eng);
    row-gap: 45px;
  }
}